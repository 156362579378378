import axios from "axios";
import { useEffect, useRef, useState } from "react";
import {Button, FormControlLabel, FormGroup, FormHelperText, InputAdornment, OutlinedInput, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
// import SaveIcon from '@material-ui/icons/Save';

import Login from './components/Login'
import EditDialogue from "./components/EditDialogue";

function App() {

  const [loading, toggleLoading] = useState(true)
  const [data, setData] = useState(null)
  const [search, setSearch] = useState('')
  const [editable, toggleEditable] = useState(false)
  const [showLoginForm, toggleLoginForm] = useState(false)
  const [isLoggedIn, toggleLoggedIn] = useState(false)
  const [helperText, setHelperText] = useState(' ')
  const [sortBy, setSortBy] = useState('name')
  const [direction, setDirection] = useState(1)
  const [showEditForm, toggleEditForm] = useState(false)
  const [editData, setEditData] = useState(null)

  const helperTextRef = useRef(null)

  useEffect(()=>{
    getUpdates()
    let interval = setInterval(()=>{
      getUpdates()
    }, [30000])
    // try {
    //   let token=JSON.parse(window.sessionStorage.getItem('token'))

    //   if (token.token) {
    //     console.log('login')
    //     toggleLoggedIn(true)
    //   }
    // } catch(e) {
    //   isLoggedIn && toggleLoggedIn(false)
    //   window.sessionStorage.setItem('token', null)
    // }
    return ()=> clearInterval(interval)
  },[])

  function getUpdates() {
    console.log('getting data')
    axios.get('/database/')
      .then(res=>{
        console.log(res)
        if (res.status === 200) {
          return res.data
        }
      })
      .then(json=>{
        console.log('json',json)
        setData(json.data)
        toggleLoading(false)
        if (json.validToken) {
          toggleLoggedIn(true)
        }
      })
      .catch(e=> {
        console.log(e, 'error fetching updates data')
      })
  }


  const handleLoginSuccess = () => {
    console.log('successfully logged in')
    toggleLoggedIn(true)
    toggleEditable(true)
    toggleLoginForm(false)
  }
  
  const handleLoginButton = () => {
    if (isLoggedIn) {
      toggleLoggedIn(false)
      toggleEditable(false)
      window.sessionStorage.setItem('token', null)
      axios.post('/login/logout')
    } else {
      toggleLoginForm(true)
    }
  }

  const handleSaveChanges = (updatedObject) => {
    console.log('test', updatedObject) 
    toggleEditForm(false)
    try {
      console.log('update', updatedObject)
      const myinit = {
        body: updatedObject
      }
      console.log('myinit', updatedObject)
      axios({
        method: 'post',
        url: '/database/update', 
        data: updatedObject
      }).then(res=>{
        console.log(res)
        if (res.status === 200) {
          return res.data
        } else toggleLoggedIn(false)
      })
      .then(json=>{
        console.log('udpate success',json)
        setData(json.data)
        toggleLoading(false)
      })
      .catch(e=> {
        console.log(e, 'error fetching updates data')
      })
    } catch (err) {
      console.log(err)
      toggleLoggedIn(false)
      setHelperText("Error updating changes")
    }
  }

  const handleClickRow = (el) => {
    if (editable) {
      console.log('click row', el)
      setEditData(el)
      toggleEditForm(true)
    }
  }

  const addNew = () => {
    if (editable) {
      setEditData(null)
      toggleEditForm(true)
    }
  }

  return (
      <div className={'py-2 flex flex-col w-full h-full max-w-screen-lg mx-auto'}>
        {/* <div className="text-2xl mx-auto font-serif">Check For Updates</div> */}
        <FormHelperText className="helperText" ref={helperTextRef} >{helperText}</FormHelperText>

        <FormGroup className={'flex gap-3 pb-2 flex-row justify-end'}>

          
          {(isLoggedIn ) && 
          <FormControlLabel
            style={{paddingRight: 10}}
            control={<Switch
              checked={editable}
              helperText={"Edit"}
              onChange={()=>toggleEditable(!editable)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />}
            labelPlacement="start"
            label={editable ? 'Edit Mode On' : 'Edit Mode Off'}

          />
        }
          {(isLoggedIn && editable) && 
            <Button 
              onClick={addNew} color="success" className="justify-end" variant="contained" endIcon={<AddCircleIcon/>}>Add</Button>
            }
          <Button 
            variant={!isLoggedIn? 'text': 'text'} 
            color={!isLoggedIn? 'primary': 'error'}  
            onClick={handleLoginButton}
            >
              {!isLoggedIn ? 'Edit' : 'Log Out'}
            </Button>
        </FormGroup>
        <TableContainer className={'border self-center overflow-hidden flex-grow relative max-h-full flex flex-col'}>
          <div >
          <Table className="h-full max-h-full overflow-hidden">
            <colgroup>
              <col width="50%" />
              <col width="25%" />
              <col width="25%" />
            </colgroup>
            <TableHead>
              <TableRow >
                <TableCell 
                  className="text-xl "
                  size="medium">
                    <div className="flex items-center justify-between">
                      <div className="flex "
                        id="product"
                      >
                        <div>Product</div>
                      

                      <TableSortLabel
                        onClick={()=>{setSortBy("name"); if (sortBy === "name") {setDirection(direction*-1)}}}
                        active={sortBy === "name" ? true : false}
                        direction={direction === -1 ? "asc" : "desc"}
                      />
                      </div>
                      <OutlinedInput className="rounded-lg flex-shrink" startAdornment={<InputAdornment><SearchIcon/></InputAdornment>} 
                        value={search}
                        onChange={(e)=>{setSearch(e.target.value)}}
                        placeholder="Search..."
                      />
                  </div>
                  </TableCell>
                  <TableCell className="text-xl" align="right" size="small">
                    <TableSortLabel
                      onClick={()=>{setSortBy("version"); if (sortBy === "version") {setDirection(direction*-1)}}}
                      active={sortBy === "version" ? true : false}
                      direction={direction === -1 ? "asc" : "desc"}
                    />
                    Version
                  </TableCell>
                  <TableCell className="text-xl" align="right" size="small">
                    <TableSortLabel
                      onClick={()=>{setSortBy("modified"); if (sortBy === "modified") {setDirection(direction*-1)}}}
                      active={sortBy === "modified" ? true : false}
                      direction={direction === -1 ? "asc" : "desc"}
                    />
                    Last Modified
                  </TableCell>
                </TableRow>
              </TableHead>
              </Table>
          </div>
          <div className="flex-grow overflow-y-scroll overflow-x-auto">
            <Table>
            <colgroup>
              <col width="50%" />
              <col width="25%" />
              <col width="25%" />
            </colgroup>
              <TableBody className="overflow-scroll">
                {(data && !loading) ? data.sort((a,b)=> {
                  if (sortBy === "name") {
                    if (a.ProductName.toLowerCase() > b.ProductName.toLowerCase()) {
                      return 1*direction
                    } else {
                      return -1*direction
                    }
                  } else if (sortBy === "version") {
                    if (a.Version < b.Version) {
                      return 1*direction
                    } else if (a.Version >  b.Version) {
                      return -1*direction
                    } else {
                      if (a.ProductName.toLowerCase() > b.ProductName.toLowerCase()) {
                        return 1*direction
                      } else {
                        return -1*direction
                      }
                    }
                  } else if (sortBy === "modified") {
                    if (a.lastModified < b.lastModified) {
                      return 1*direction
                    } else {
                      return -1*direction
                    }
                  }

                })
                .filter(el=>{
                  if (search) {
                    let re = new RegExp(search, 'gi')
                    return el.ProductName.match(re)
      
                  } else 
                  return el
                }).map((el, i)=>{
                  let date = new Date(el.lastModified)
                  return <TableRow style={{cursor: editable?'pointer':'default'}} onClick={()=>handleClickRow(el)} key={el.ID}>
                    <TableCell className="flex flex-row gap-5 items-center">
                      <a style={{color: '#4b4b4b', textDecoration: el.ReleaseNotesUri ? 'underline': 'none'}} href={el.ReleaseNotesUri ? el.ReleaseNotesUri  : null} target="_blank">{el.ProductName}</a> 
                      <a style={{color: '#4b4b4b'}} href={el.ProductInstallUri} target="_blank"><OpenInNewIcon fontSize="small" /></a>
                    </TableCell>
                    <TableCell 
                      align="right" 
                      size="small">
                        {el.Version}
                      </TableCell> 
                    <TableCell 
                      align="right" 
                      size="small">{date.toLocaleDateString('en-CA')}
                    </TableCell>
                  </TableRow>
                }): 
                <TableRow>
                  <TableCell colSpan={3} style={{textAlign: 'center'}}>
                  <div className="lds-dual-ring"></div>
                  </TableCell>
                </TableRow>
                }
            </TableBody>
          </Table>
        </div>
      </TableContainer>
      <Login open={showLoginForm} toggleLoginForm={()=>toggleLoginForm(false)} handleLoginSuccess={handleLoginSuccess}/>
      <EditDialogue open={showEditForm} handleClose={()=>toggleEditForm(false)} data={editData} saveChanges={handleSaveChanges}/>
      </div>
  );
}

export default App;
