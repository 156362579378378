import React, {useState, useEffect} from 'react'
import {  Input, Modal, FormGroup, FormControl, InputLabel, Button, Paper, Dialog, FormLabel, FormControlLabel, TextField, FormHelperText } from '@mui/material'


const initValues = {
    ID: null,
    ProductName: '', 
    Version: '', 
    ReleaseNotesUri: '', 
    ProductInstallUri: '', 
}

const EditDialogue = (props) => {

    const [values, setValues] = useState(initValues)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(()=> {
        console.log('render');
        return (()=>{
            console.log('unmount')
        })
    },[])

    useEffect(()=> {
        console.log('data changed', props.data)
        if (props.data) {
            console.log('add data')
            setValues(props.data)
        } else {
            setValues(initValues)
        }
    }, [props.data])

    const handleChange = (prop) => (event) => {
        console.log(prop, event.target.value)
        setValues({ ...values, [prop]: event.target.value});
    };

    const handleSave = (e) => {
        e.preventDefault()
        if (props.data !== values) {
            console.log('changes detected', values)
            props.saveChanges(values)
            setValues(initValues)
        } else {
            console.log('No changes Detected')
            setErrorMessage('No Changes Detected')
            setTimeout(()=> {
                setErrorMessage('')
            }, [5000])
        }

    }

    return (
        <Dialog className={''} open={props.open} onClose={props.handleClose}>
            <Paper className={'p-4 max-w-full w-[500px]'}>
                <div className="text-2xl font-semibold">{values.ID ? "Edit Product Info": "Add New Product"}</div>
                {values && 
                <FormGroup className={'flex flex-col gap-3'}>
                        <FormControl className='flex flex-col gap-5 py-3'>
                            <TextField id="name-field" label="Name" variant='standard' value={values.ProductName} onChange={handleChange('ProductName')}/>
                            <TextField id="version-field" label="Version" variant='standard' value={values.Version} onChange={handleChange('Version')}/>
                            <TextField id="install-field" label="Install URL" variant='standard' value={values.ProductInstallUri} onChange={handleChange('ProductInstallUri')}/>
                            <TextField id="release-field" label="Release Notes URL" variant='standard' value={values.ReleaseNotesUri ? values.ReleaseNotesUri : ''} onChange={handleChange('ReleaseNotesUri')}/>

                            {/* <InputLabel htmlFor="name-field">Name</InputLabel>
                            <Input id="name-field" value={values.ProductName} onChange={handleChange('ProductName')}/> */}
                        </FormControl>
                        {/* <FormControl>
                            <InputLabel htmlFor="version-field">Version</InputLabel>
                            <Input id="version-field" value={values.Version} onChange={handleChange('Version')}/>
                        </FormControl>
                        <FormControl>
                            <InputLabel htmlFor="install-field">Product Install URL</InputLabel>
                            <Input id="install-field" value={values.ProductInstallUri} onChange={handleChange('ProductInstallUri')}/>
                        </FormControl>
                        <FormControl>
                            <InputLabel htmlFor="release-field">Release Notes URL</InputLabel>
                            <Input id="release-field" value={values.ReleaseNotesUri ? values.ReleaseNotesUri : ''} onChange={handleChange('ReleaseNotesUri')}/>
                        </FormControl> */}
                    <FormControl className={'flex flex-col gap-2'}>
                    <Button 
                        variant="contained" 
                        type="submit" 
                        onClick={handleSave}
                        >Save Changes</Button>
                        <Button 
                            variant="text" 
                            type="submit" 
                            onClick={props.handleClose}
                            >Cancel</Button>
                        <FormHelperText error={errorMessage ? true : false}>{errorMessage}</FormHelperText>

                    </FormControl>
                </FormGroup>}
            </Paper>
        </Dialog>
    );
}

export default EditDialogue