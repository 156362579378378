import React, {useEffect, useState} from 'react'
import { makeStyles, Modal, FormControl, InputLabel, OutlinedInput,FormHelperText,InputAdornment, IconButton, Button, Paper, Dialog, FormGroup } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import axios from 'axios'

const Login = ({open, handleLoginSuccess, toggleLoginForm}) => {

    const [values, setValues] = useState({
        username: '',
        password: '',
        errormessage: '',
        showPassword: false
    })

    async function checkLogin(username, password) {
        try {
          // const loginData = await API.get('updateapi',`/login/${username || 'asdf'}/${password || 'asdf'}`)
          axios({
            method: 'post', 
            url: '/login', 
            data: {
              username: username, 
              password: password
              }
            })
            .then(res=>{
              if (res.status === 200) {
                return res.data
              }
            })
            .then(loginData => {
              console.log('login success', loginData)
              let sessionData = {username: values.username, password: values.password, token: loginData.accessToken}
              setValues({...values, password: '', errormessage: ''})
              window.sessionStorage.setItem('token', JSON.stringify(sessionData))
              handleLoginSuccess();
            })
            .catch(e=>{
              setValues({...values, errormessage: 'Invalid Username or Password'})
            })
          // console.log('data: ', loginData)
          // if (loginData.success) {
          //   setValues({...values, password: '', errormessage: ''})
          //   props.handleLoginSuccess();
          //   // toggleLogin(true)
          //   // props.setLogin()
          //   // console.log(props.history.push('/'))
          // } else {
          //   setValues({...values, errormessage: 'Invalid Username or Password'})
          // }
          // if (!loginData.validUser) {
          //   setValues({...values, usernameerror: 'Invalid User.', username: '', password: ''})
          // } else if (!loginData.validPassword) {
          //   setValues({...values, passworderror: 'Invalid Password.', password: ''})
          // } else {
          //   setValues({...values, password: '', passworderror: '', usernameerror: ''})
          //   props.handleLoginSuccess();
          //   // toggleLogin(true)
          //   // props.setLogin()
          //   // console.log(props.history.push('/'))
          // }
        } catch (err) { 
          console.log('error?', err)
          // toggleError(true)
        }
      } 

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value, errormessage: '', usernameerror: '', passworderror: '' });
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('submit');
        checkLogin(values.username, values.password)
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
      };

    const handleClose = () => {
      setValues({
        username: '',
        password: '',
        errormessage: '',
        showPassword: false
      })
      toggleLoginForm()
    }

    return (
      <Dialog open={open} onClose={handleClose} className='self-start'>
        <Paper style={{padding: 10}}>
        <FormGroup className={'flex flex-col gap-3'}>
            <div className={'text-xl'}>Log-in to edit table</div>

            <FormControl error={values.errormessage !== ''} className={''} variant="outlined">
              <InputLabel className='bg-white px-1' htmlFor="username-textfield">Username</InputLabel>
              <OutlinedInput
                  id="username-textfield"
                  value={values.username}
                  onChange={handleChange('username')}
                  placeholder="Username"
                  labelWidth={75}
                  
              />
            </FormControl>
            <FormControl error={values.errormessage !== ''} className={''} variant="outlined">
            <InputLabel className='bg-white px-1' htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
                }
                labelWidth={70}
            />
            </FormControl>
            <FormHelperText style={{paddingBottom: 10}} error={values.errormessage !== ''}>{values.errormessage}</FormHelperText>
            <Button 
            color="primary" 
            variant="contained" 
            type="submit" 
            onClick={handleSubmit}
            >Log In</Button>
            <Button 
            color="primary" 
            variant="contained" 
            type="submit" 
            onClick={handleClose}
            >Cancel</Button>
        </FormGroup>
        </Paper>
    </Dialog>)
}

export default Login;